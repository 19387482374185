@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-family: "Lato", sans-serif;
  // This defines what 1rem is
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%
  font-weight: 400;

  @include respound(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1rem = 9px, 9/16 = 56.25%
  }

  @include respound(tab-port) {
    // width < 900?
    font-size: 50%; //1rem = 8px, 8/16 = 50%
  }

  @include respound(big-desktop) {
    font-size: 75%; //1rem = 12 12/16
  }
}

body {
  box-sizing: border-box;
  // background-color: $color-primary-dark;

  background-color: var(--themeBgColor);
}

.main {
  min-height: 100vh;
  background: linear-gradient(
      to bottom right,
      transparent 50%,
      var(--themeBgColorTwo) 0
    )
    100% 100%;
  background-attachment: fixed;

  @include respound(phone) {
    background: var(--themeBgColor);
  }
}

.hide-element {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

::selection {
  color: $color-primary;
  background-color: $color-black;
}
