body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: var(--themeSimpleTextColor);
}

.heading-primary {
  color: var(--themeSimpleTextColor);
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 1rem;
  animation: moveInLeft 1s ease-out;
  animation-fill-mode: backwards;
  animation-delay: 0.5s;

  &--blue {
    background-color: var(--themePrimaryHeadingBgColor);
    color: $color-primary;
    font-size: 4rem;
    font-weight: 200;
  }

  &--white {
    font-size: 4.5rem;
    font-weight: 300;
  }
}

.heading-secondary {
  @include u-margin-bottom-big;
  color: var(--themeGreyToBlackColor);
  font-family: "Lato", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  font-style: italic;
  line-height: 1.2;
  text-align: center;

  &--blue {
    color: $color-primary;
    font-size: 3rem;
    font-weight: 100;
    font-style: italic;
    letter-spacing: 0.2rem;
    background-color: var(--themePrimaryHeadingBgColor);
  }
}

.heading-tertiary {
  color: var(--themeSimpleTextColor);
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
}

.heading-title {
  color: var(--themePrimaryTextColor);
  font-family: "Lato", sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.3rem;
  margin-left: 2.5rem;
}

.heading-desc-title {
  color: var(--themePrimaryTextColor);
  font-family: "Lato", sans-serif;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 0.3rem;
  padding-bottom: 0;
}

.heading-sub-title {
  color: var(--themePrimaryHeadingColor);
  font-family: "Lato", sans-serif;
  font-style: italic;
  font-size: 2rem;
  font-weight: 100;
  letter-spacing: 0.1rem;
  display: var(--themeBlock);
  background-color: var(--themePrimaryHeadingBgColor);
  padding: 0 var(--themeSpace);
}

.paragraph {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.6;

  @include respound(phone) {
    font-size: 1.8rem;
    text-align: justify;
  }
}
