// MAIN COLORS
$color-unset: unset;
$color-white: #fff;
$color-black: #000;
$color-black-two: #333;
$color-error: #e74c3c;

$color-blue-light-1: #f7f7f7;
$color-grey-light-2: #eee;

$color-grey-dark: rgb(112, 112, 112);
$color-grey-dark-transparent: rgba(112, 112, 112, 0.1);

// DARK THEME
$color-primary: #039ae5;
$color-primary-dark: #0e1d2c;
$color-primary-dark-light: #3483d8;
$color-primary-two-dark-light: rgba(17, 34, 52, 0.5);


// LIGHT THEME
$color-primary-two: #112234;
$color-primary-light: #ebedf0;
$color-primary-light-dark: #d4d9e1;
$color-primary-two-light-dark: rgba(212,217,225, 0.5);



// FONT SIZE
$default-font-size: 1.5rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// DISPLAY
$block: block;
$inline-block: inline-block;

// SPACES
$space-0: 0rem;
$space-025: 0.25rem;
$space-050: 0.50rem;
$space-1: 1rem;
