.footer {
  @include container;
  width: 100%;
  background: transparent;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10rem;

    @include respound(phone) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__social {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    list-style: none;
    padding-left: 1rem;
  }

  &__item {
    display: inline;
    &:last-child {
      padding: 0;
    }
  }

  &__link {
    color: var(--themeSimpleTextColor);
    text-decoration: none;

    @include focusVisible;
  }

  &__icon {
    width: 3.5rem;
    height: 3.5rem;
    fill: var(--themeSimpleTextColor);
    transition: all 0.2s ease-in;

    &:hover {
      fill: $color-primary;
    }
  }
}
