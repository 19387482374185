.contact-me-wrapper {
  @include container;
  min-height: calc(100vh - 10rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-me {
  @include respound(tab-land) {
    @include u-margin-top-big;
  }
}
