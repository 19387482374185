.project-wrapper {
  @include container;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects {
  @include respound(phone) {
    @include u-margin-top-big;
  }

  &__main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include respound(tab-port) {
      flex-direction: column;
    }
  }

  &__main--project {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    @include u-margin-bottom-medium;

    @include respound(phone) {
      align-items: center;
      flex-direction: column;
    }
  }

  .project-img {
    width: 40%;

    &--link {
      @include focusVisible;
    }

    @include respound(phone) {
      width: 100%;
    }

    & > a img {
      width: 85%;
      height: 100%;

      @include respound(phone) {
        width: 100%;
      }
    }
  }
  
  .project-desc {
    width: 60%;
    padding-left: 5rem;

    @include respound(phone) {
      width: 100%;
    }

    @include respound(tab-port) {
      padding-left: 0;
    }
  }
}
