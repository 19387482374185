.header {
  position: fixed;
  left: 0;
  top: -0.2rem;
  width: 100%;
  background-color: var(--themeBgColor);
  box-shadow: 0px 2px 6px 0px var(--themeBoxShadow);
  z-index: 999;

  &__wrapper {
    @include container;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5.2rem;
  }

  &__wrapper--link {
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    user-select: none;
    @include focusVisible;
  }
}

.nav {
  &__main {
    @include respound(tab-land) {
      margin-left: calc(3.5rem / 2);
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
  }

  &__item {
    padding: 0 4rem 0 0;

    @include respound(phone) {
      padding: 0 1.25rem 0 0;
    }

    &:last-child {
      padding: 0;
    }
  }

  &__link {
    position: relative;
    display: block;
    color: var(--themeSimpleTextColor);
    cursor: pointer;
    font-size: $default-font-size;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
    @include focusVisible;
    // transition: all 0.2s ease-in-out;

    &.nav-active {
      color: $color-primary;
    }
  }
}

.burger-menu-wrapper {
  width: 3rem;
  height: 2.5rem;
}

.burger-menu {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
}

.burger {
  position: absolute;
  background: var(--themeSimpleTextColor);
  width: 100%;
  height: 0.25rem;
  top: 50%;
  right: 0;
  margin-top: -2px;
  border-radius: 1rem;
  opacity: 1;

  &::before {
    position: absolute;
    background: var(--themeSimpleTextColor);
    width: 3rem;
    height: 0.25rem;
    top: 7px;
    content: "";
    display: block;
    border-radius: 1rem;
  }

  &::after {
    position: absolute;
    background: var(--themeSimpleTextColor);
    width: 3rem;
    height: 0.25rem;
    bottom: 7px;
    content: "";
    display: block;
    border-radius: 1rem;
  }

  &::after,
  &::before,
  & {
    transition: all 0.2s ease-in-out;
  }
}

.burger-menu.menu-on .burger::after {
  transform: rotate(-45deg);
  bottom: 0;
}

.burger-menu.menu-on .burger::before {
  transform: rotate(45deg);
  top: 0;
}

.burger-menu.menu-on .burger {
  background: transparent;
}

// Custom Top Header Scroll
progress {
  position: fixed;
  left: 0;
  bottom: 0;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0.2rem;
  border: none;
  background: transparent;
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  background: linear-gradient(
    to left,
    rgb(3, 119, 190),
    rgb(3, 154, 229),
    rgb(42, 182, 246),
    rgb(129, 212, 250),
    rgb(179, 229, 252),
    rgb(226, 245, 254)
  );
  background-attachment: fixed;
}
progress::-moz-progress-bar {
  background: linear-gradient(
    to left,
    rgb(3, 119, 190),
    rgb(3, 154, 229),
    rgb(42, 182, 246),
    rgb(129, 212, 250),
    rgb(179, 229, 252),
    rgb(226, 245, 254)
  );
  background-attachment: fixed;
}
